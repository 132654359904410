<template>
  <router-link class="w-1/5 mt-5 mx-6 work-result-item" :to="{ name: 'detail-view', params: {identifier: result.slug } }">
    <img class="w-full work-result-image" :class="noImage ? 'p-10' : null" :src="imageSrc" />
    <div class="mt-2 text-blue-800 work-result-title">{{ result.title }}</div>
    <div class="flex justify-between">
      <div class="text-gray-medium work-result-repository" v-if="result.repository">{{ result.repository.name }}</div>
      <div class="text-gray-medium font-mono text-xs my-auto work-result-accessionNumber" v-if="result.accessionNumber">{{ result.accessionNumber }}</div>
    </div>
    <div class="text-gray-medium work-result-classification" v-if="result.classification">{{ result.classification.name }}</div>
    <div class="text-gray-medium" v-if="result.location">{{ result.location.name }}</div>
    <div class="text-gray-medium work-result-date" v-if="result.date">{{ result.date.name }}</div>
  </router-link>
</template>

<script>
  import PlaceholdImage from '../../../../images/no-image.svg';

  export default {
    props: {
      result: Object,
    },
    computed: {
      noImage() {
        return !this.result.thumbUrl && !this.result.imageUrl;
      },
      imageSrc() {
        return this.result.thumbUrl || this.result.imageUrl || PlaceholdImage;
      }
    }
  };
</script>
