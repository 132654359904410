<template>
    <div class="splash">
        <header class="header-container">
            <article class="item c">
                <img class="logo" :src="cImage">
            </article>
            <article class="item o-1">
                <img class="logo" :src="oImage">
            </article>
            <article class="item l">
                <img class="logo" :src="lImage">
            </article>
            <article class="item o-2">
                <img class="logo" :src="o2Image">
            </article>
            <article class="item r">
                <img class="logo" :src="rImage">
            </article>
        </header>
    </div>
</template>

<script>
import cImage from '@public/images/splash/c.png';
import lImage from '@public/images/splash/l.png';
import oImage from '@public/images/splash/o.png';
import o2Image from '@public/images/splash/o2.png';
import rImage from '@public/images/splash/r.png';

export default {
  data() {
    return {
      cImage,
      oImage,
      lImage,
      o2Image,
      rImage
    };
  }
}
</script>

<style lang="scss">
div.splash {
    background-color: black;
    font-family: "Heebo", sans-serif;
    font-weight: 700;

    /*--head --*/

    .header-container {
        display: flex;
        margin: 0 auto;
    }

    .item {
        width: 20%;
    }

    .c {
        background-color: #4e6b9e;
    }

    .o-1 {
        background-color: #faa400;
        overflow: hidden;
        background-image: url(@public/images/splash/o.jpg); // Works
        -webkit-animation: switcho 3s;
        animation: switcho 3s;
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }

    @-webkit-keyframes switcho {
        0% {
            background-image: url(@public/images/splash/blank.png);
        }
        50% {
            background-image: url(@public/images/splash/blank.png);
        }
        100% {
            background-image: url(@public/images/splash/o.jpg);
        }
    }

    @keyframes switcho {
        0% {
            background-image: url(@public/images/splash/blank.png);
        }
        50% {
            background-image: url(@public/images/splash/blank.png);
        }
        100% {
            background-image: url(@public/images/splash/o.jpg);
        }
    }

    .l {
        background-color: #80b37d;
    }

    .o-2 {
        padding-top: 0%;
        background-color: black;
    }

    .r {
        background-color: #f25a29;
        overflow: hidden;
        background-image: url(@public/images/splash/r.jpg);
        -webkit-animation: switchr 3s;
        animation: switchr 3s;
        background-repeat: no-repeat;
        background-position: right top;
        background-size: cover;
    }

    @-webkit-keyframes switchr {
        0% {
            background-image: url(@public/images/splash/blank.png);
        }
        50% {
            background-image: url(@public/images/splash/blank.png);
        }
        100% {
            background-image: url(@public/images/splash/r.jpg);
        }
    }

    @keyframes switchr {
        0% {
            background-image: url(@public/images/splash/blank.png);
        }
        50% {
            background-image: url(@public/images/splash/blank.png);
        }
        100% {
            background-image: url(@public/images/splash/r.jpg);
        }
    }

    .logo {
        width: 100%;
        padding: 20% 6%;
    }

    .logo-wide {
        width: 100%;
        padding: 0 5%;
    }

    /*--info--*/

    .info-container {
        color: #4e6b9f;
        text-align: center;
        padding-bottom: 10em;
    }

    h1 {
        font-size: 2em;
        color: #f25a29;
        padding-top: 50px;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.7em;
        color: white;
    }

    h4 {
        font-size: 1em;
        color: #faa400;
        padding: 6em 0 1em;
    }

    h4 a {
        color: white;
    }

    h5 {
        font-size: 1.7em;
        color: white;
        padding-top: 30px;
    }

    p {
        font-size: 1em;
        line-height: 1.4em;
    }

    p a {
        font-size: 0.7em;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        color: #4e6b9f;
    }

    .title {
        font-size: 0.7em;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        padding: 5em 0 1em;
        color: #f25a29;
    }

    /*--sponsors--*/

    .sponsor-container {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        padding: 0 5%;
        background-color: white;
        min-height: 2em;
        align-items: center;
    }

    .sponsor-item {
        margin: 2% 2% 1%;
    }

    .sponsor-item img {
        width: 100%;
    }

    @media only screen and (max-width: 970px) {
        h2 {
            font-size: 1.7em;
        }
        .sponsor-item {
            margin: 2% 1% 1%;
        }
    }

    @media only screen and (max-width: 767px) {
        h2 {
            font-size: 1.3em;
        }
        .sponsor-container {
            flex-wrap: wrap;
        }
        .sponsor-item {
            width: 22%;
        }
        .smaller {
            width: 10%;
        }
    }

    @media only screen and (max-width: 600px) {
        h1 {
            font-size: 1.7em;
        }
        h2 {
            font-size: 1em;
        }
        .logo {
            width: 100%;
            padding: 50% 6%;
        }
        .sponsor-item {
            width: 33%;
        }
        .smaller {
            width: 12%;
        }
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font-size: 0.9em;
        }
        p {
            font-size: 0.8em;
        }
        .logo {
            width: 100%;
            padding: 50% 6%;
        }
    }
}

</style>