import { createApp } from 'vue';
import { apolloProvider } from './gql/apollo';

import App from './App.vue';
import router from './router';

import 'vite/modulepreload-polyfill';

// configureCompat({ WATCH_ARRAY: false });

const app = createApp(App);
app.use(router);
app.use(apolloProvider);

export default app;