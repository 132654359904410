<template>
  <div class="border-gray-300 border-b-2">
    <CategoryNav v-model:selectedCategory="selectedCategory" />
    <FacetsDropdown v-if="selectedCategory" :selected-category="selectedCategory" :selected-facets="selectedFacets" :search="search" @selected="onFacetSelect" />
    <SelectedFacets :facetsByCategory="selectedFacets" @clear-facet="onClearFacet" />
  </div>
</template>

<script>
  import CategoryNav from './CategoryNav.vue';
import FacetsDropdown from './FacetsDropdown.vue';
import SelectedFacets from './SelectedFacets.vue';

  export default {
    props: ['search', 'selectedFacets'],
    components: {
      CategoryNav,
      FacetsDropdown,
      SelectedFacets,
    },
    data() {
      return {
        selectedCategory: null,
        facets: null,
      }
    },
    methods: {
      onClearFacet(facet) {
        this.$emit('clear', facet);
        this.selectedCategory = null;
      },
      onFacetSelect(facet) {
        this.$emit('select', { category: this.selectedCategory, facet });
        this.selectedCategory = null;
      }
    },
  }
</script>
