<template>
  <ul class="text-center mt-6" id="navigationLinks">
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'home-view' }, 'home')">Home</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/search" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'search' })">Search Works</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/search?view=map" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({name: 'map'})">Search Map</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/pigments" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'pigments-view' })">Browse by Pigment</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/colors" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'colors-view' })">Browse by Color</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/elements" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'elements-view' })">Browse by Element</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/methodologies" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'methodologies-view' })">Methodologies Glossary</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/about" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'about')">About MCH</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/process" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'process')">Our Process</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/bibliography" class="cursor-pointer border-b-2 border-transparent hover:border-black" @click.prevent="nav({ name: 'dynamic-view' }, 'bibliography')">Bibliography</a></li>
    <li class="py-4 text-2xl uppercase tracking-wider"><a href="/mcih-admin/" class="cursor-pointer border-b-2 border-transparent hover:border-black">Admin</a></li>
  </ul>
</template>

<script>
  export default {
    methods: {
      nav(to, slug = null) {
        this.$emit('navigated');
        // the default search won't work from ?view=map, and need to handle ?view differently ?
        if(to.name == "search"){
          this.$router.push({ path: '/search', query: { view: 'thumb' }});
          return;
        }
        if(to.name == "map"){
          this.$router.push({ path: '/search', query: { view: 'map' }});
          return;
        }
        if (to.name === "dynamic-view") {
        // If slug is provided, navigate to the dynamic-view with the slug
          if (slug) {
            this.$router.push({ name: 'dynamic-view', params: { slug } });
            return;
            }
        }
        if (to.name === "home-view") {
          if (slug) {
            this.$router.push({ name: 'home-view', params: { slug } });
            return;
            }
        }
        if (this.$route.name !== to.name) {
          this.$router.push(to);
        }
      },
    }
  }
</script>

<style scoped>
  #navigationLinks {
    margin-top: 0px;
    padding-top: 24px;
  }
</style>