<template>
  <div class="container mx-auto">
    <h1>Search Works</h1>
    <input class="border border-gray-800 w-full mt-5 placeholder-gray-medium" v-model="search" type="search" placeholder="search by keyword, title, pigment, etc." />
    <WorkFacets v-if="allWorks" :search="search" :selected-facets="selectedFacets" @select="onSelectFacet" @clear="onClearFacet" />
    <div v-if="dateRange">
      <VueSlider
        :min="minYear"
        :max="maxYear"
        :modelValue="dateRange"
        @drag-end="onDateRangeDragEnd"
        ref="slider"
        :marks="marks"
        :clickable="false"
        :drag-on-click="true"
        :interval=1
        class="mb-10"
      />
    </div>
    <template v-if="allWorks && allWorks.works">
      <div class="flex justify-between py-4 results-container">
        <div id="results-count">{{ allWorks.works.length }} results</div>
        <div>
          <a href @click.prevent="setView('map')" :class="view == 'map' ? 'text-gray-900' : 'text-gray-400'">Map</a>
          <a href @click.prevent="setView('thumb')" :class="view == 'thumb' ? 'text-gray-900' : 'text-gray-400'">Thumbnails</a>
        </div>
      </div>

      <MapResults v-if="view == 'map'" :results="allWorks.works" />
      <ThumbnailResults v-if="view == 'thumb'" :results="allWorks.works" />
    </template>
  </div>
</template>

<script>
  import gql from 'graphql-tag';
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  import MapResults from '../components/search-results/MapResults.vue';
import ThumbnailResults from '../components/search-results/ThumbnailResults.vue';
import WorkFacets from '../components/work-facets/WorkFacets.vue';

  import FACET_KEYS from '../components/work-facets/facets';

  export default {
    components: {
      MapResults,
      ThumbnailResults,
      WorkFacets,
      VueSlider,
    },
    data() {
      return {
        allWorks: [],
        view: this.$route.query.view || "thumb",
        minYear: 500,
        maxYear: 2000,
        marks: [500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000] // array from 500 to 2000 with steps of 100
      };
    },
    computed: {
      search: {
        get() {
          return this.$route.query.q || '';
        },
        set(value) {
          const queries = { ...this.$route.query };
          queries.q = value;
          this.$router.replace({ query: queries });
        },
      },
      selectedFacets: {
        get() {
          const facets = FACET_KEYS.reduce((map, key) => {
            const selectedValue = this.$route.query[key];
            if (selectedValue) {
              return {...map, [key]: selectedValue};
            }
            return map;
          }, {});
          return facets;
        },
        set(value) {
          const queries = {
            ...this.$route.query,
            ...value,
          };
          this.$router.replace({ query: queries });
        },
      },
      startYear() {
        return this.$route.query.start || '';
      },
      endYear() {
        return this.$route.query.end || '';
      },
      dateRange() {
        if (this.startYear && this.endYear) {
          const start = parseInt(this.startYear);
          const end = parseInt(this.endYear);
          return [start, end];
        }
        return [this.minYear, this.maxYear];
      },
    },
    methods: {
      onDateRangeDragEnd() {
        const [start, end] = this.$refs.slider.getValue();
        const queries = { ...this.$route.query, start, end };
        this.$router.replace({ query: queries });
      },
      onClearFacet(facet) {
        const queries = Object.keys({...this.$route.query})
          .filter(category => category !== facet.category)
          .reduce((map, category) => {
            return category === 'q'
              ? { ...map, [category]: this.$route.query.q }
              : { ...map, [category]: this.selectedFacets[category] };
          }, {});
        this.$router.replace({ query: queries });
      },
      onSelectFacet({category, facet}) {
        this.selectedFacets = {
          ...this.selectedFacets,
          [category]: facet,
        };
      },
      setView(view){
        this.view = view;
        const queries = {
          ...this.$route.query,
          "view": this.view
        };
        this.$router.replace({ query: queries });
      }
    },
    apollo: {
      allWorks: {
        query: gql`query SearchWorks($title: String!, $kinds: String!, $collections: String!, $artists: String!, $mediums: String!, $locations: String!, $workDates: String!, $methodologies: String!, $pigments: String!, $colors: String!, $elements: String!, $startDate: Date, $endDate: Date) {
          allWorks(search: $title, kindsFacet: $kinds, collectionsFacet: $collections, artistsFacet: $artists, mediumsFacet: $mediums, locationsFacet: $locations, workDatesFacet: $workDates, methodologiesFacet: $methodologies, pigmentsFacet: $pigments, colorsFacet: $colors, elementsFacet: $elements, startDate: $startDate, endDate: $endDate) {
            edges {
              node {
                id
                title
                slug
                accessionNumber
                imageUrl
                thumbUrl
                date {
                  name
                  startDate
                  endDate
                }
                classification {
                  name
                }
                location {
                  latitude
                  longitude
                  name
                }
                repository {
                  name
                }
              }
            }
          }
        }`,
        variables () {
          const defaultFacets = FACET_KEYS.reduce((map, key) => ({...map, [key]: ''}), {});
          const startDate = this.startYear ? `${String(this.startYear).padStart(4, '0')}-01-01` : null;
          const endDate = this.endYear ? `${this.endYear}-12-31` : null;
          const dates = startDate && endDate ? { startDate, endDate } : {};
          return {
            title: this.search,
            ...dates,
            ...defaultFacets,
            ...this.selectedFacets,
          };
        },
        update: data => {
          const worksMap = data.allWorks.edges.reduce((map, edge) => ({...map, [edge.node.id]: edge.node}), {});
          return {
            ...data.allWorks,
            works: Object.keys(worksMap).map(id => worksMap[id]),
          };
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
