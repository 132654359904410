<template>
    <b><div class="container top" v-html="block.value.name"></div></b>
    <div class="container bottom">
        <img :src="block.value.logo.url" :alt="block.value.logo.title" height="100px" width="150px"/>
    </div>
</template>

<script>
export default {
  props: {
    block: Object,
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>