<template>
  <div class="container mx-auto">
    <h1 v-if="page">{{ page.title }}</h1>
    <div v-if="page" v-for="(block, index) in page.body" :key="index" class="block">
      <div v-if="block.type === 'collapsible'">
        <CollapsibleBlock :block="block" :citationMap="citationMap" :visibilityMap="visibilityMap" :index="index"/>
      </div>
      <div v-else>
        <DynamicBlock :block="block" :citationMap="citationMap"/>
      </div>
      <hr v-if="index < page.body.length - 1" class="block-separator">
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DynamicBlock from '../components/DynamicBlock.vue';
import CollapsibleBlock from '../components/dynamic-blocks/CollapsibleBlock.vue';

export default {
  components: {
    DynamicBlock,
    CollapsibleBlock,
  },
  data() {
    return { 
      visibilityMap: new Map(),
      citationMap: new Map(),
    };
  },
  apollo: {
    page: {
      query: gql`
        query($slug: String!) {
          pageBySlug(slug: $slug) {
            id
            title
            slug
            body
          }
        }
      `,
      variables() {
        return {
          slug: this.$route.params.slug || 'home',
        };
      },
      update: (data) => data.pageBySlug,
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(newPage) {
        if (newPage && newPage.title && newPage.slug !== 'home') {
          document.title = `${newPage.title} - MCH`;
        }
        if (newPage && newPage.body) {
          newPage.body.forEach((block) => {
            if (block.type === 'citation' && block.value.id) {
              this.fetchAndDisplayCitation(block);
            }
            if (block.type === 'collapsible') {
              block.value.content.forEach((innerBlock) => {
                if (innerBlock.type === 'citation' && innerBlock.value.id) {
                  this.fetchAndDisplayCitation(innerBlock);
                }
              });
            }
          });
        }
      },
    },
  },
  methods: {
    async fetchAndDisplayCitation(block) {
      try {
        const citationId = block.value.id;
        const response = await this.$apollo.query({
          query: gql`
            query {
              citationsByCollection(collectionId: "${citationId}")
            }
          `,
        });

        const responseData = response.data.citationsByCollection;
        this.citationMap.set(block.value.id, responseData);
      } catch (error) {
        console.error('Error fetching citation:', error);
      }
    },
  },
};
</script>

<style scoped>
@import '../../../css/dynamic.css';
</style>