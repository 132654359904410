<template>
  <a class="hover:underline px-2" :class="{ underline: selected }" href @click.prevent="$emit('selected', slug)"><slot /></a>
</template>

<script>
  export default {
    props: ['active-slug', 'slug'],
    computed: {
      selected() {
        return this.activeSlug === this.slug;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
