<template>
    <div class="container" v-html="block.value"></div>
</template>

<script>
export default {
  props: {
    block: Object
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>