<template>
  <div class="container mx-auto">
    <h1>Browse by Elements</h1>
    <div class="flex flex-wrap mt-10" id="elements">
      <div class="mx-4 element" :class="element.symbol" v-for="element in allElements" :key="element.uuid">
        <ElementBox :element="element" />
      </div>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import ElementBox from '../components/ElementBox.vue';

  export default {
    components: { ElementBox },
    data() {
      return {
        allElements: [],
      }
    },
    apollo: {
      allElements: {
        query: gql`query Elements {
          allElements {
            edges {
              node {
                uuid
                name
                description
                atomicNumber
                symbol
                workCount
              }
            }
          }
        }`,
        update: data => data.allElements.edges.map(e => e.node),
      }
    }
  }
</script>
