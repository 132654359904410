export default [
  'kinds',
  'collections',
  'artists',
  'mediums',
  'locations',
  'workDates',
  'methodologies',
  'pigments',
  'colors',
  'elements',
];
