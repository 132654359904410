<template>
  <div class="flex my-3">
    <div class="flex-1">
      <div class="py-4 text-center border-gray-700 border-b-2 border-r-2">Artwork Data</div>
      <div class="py-4 text-center border-gray-700 border-r-2">
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="kinds">Type</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="collections">Collection</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="artists">Artist</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="mediums">Medium</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="locations">Location</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="workDates">Date</CategoryNavItem>
      </div>
    </div>
    <div class="flex-1">
      <div class="py-4 text-center border-gray-700 border-b-2">Analysis Data</div>
      <div class="py-4 text-center border-gray-700">
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="methodologies">Methodology</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="pigments">Pigments</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="colors">Colors</CategoryNavItem>
        <CategoryNavItem @selected="onSelect" :active-slug="value" slug="elements">Elements</CategoryNavItem>
      </div>
    </div>
  </div>
</template>

<script>
  import CategoryNavItem from './CategoryNavItem.vue';

  export default {
    props: ['value'],
    components: {
      CategoryNavItem,
    },
    methods: {
      onSelect(category) {
        this.$emit('update:selectedCategory', category);
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
