<template>
  <div class="mt-4 p-4 bg-gray-100 border-t-2 border-b-2 border-gray-700 h-72 flex flex-col flex-wrap justify-top facets-dropdown">
    <div class="text-center my-auto" v-if="facetList.length === 0">
      <div v-if="$apollo.loading" class="text-gray-400 text-lg">Loading...</div>
      <div v-else class="text-gray-400 text-lg">No data published yet.</div>
    </div>
    <div class="py-1" v-for="(facet, index) in facetList" :key="index">
      <a class="inline-flex hover:underline facet-choice" href @click.prevent="$emit('selected', facet.label)">
        <span class="text-lg facet-label">{{ facet.label }}</span>
        <span class="text-xs ml-1 py-1 px-2 my-auto font-bold leading-none text-gray-600 bg-gray-300 rounded-full facet-count">{{ facet.count }}</span>
      </a>
    </div>
  </div>
</template>

<script>
  import gql from 'graphql-tag';

  import FACET_KEYS from './facets';

  //  :facets="categoryFacets" :selectedFacets="selectedCategoryFacets"
  export default {
    props: ['search', 'selectedCategory', 'selectedFacets'],
    data() {
      return {
        facets: {},
      };
    },
    computed: {
      facetList() {
        return Object.keys(this.categoryFacets)
          .map(label => ({ label, count: this.categoryFacets[label] }))
          .filter(facet => facet.count > 0)
          .sort((a, b) => a.label > b.label);
      },
      categoryFacets() {
        return this.facets[this.selectedCategory] || {};
      },
      startYear() {
        return this.$route.query.start || '';
      },
      endYear() {
        return this.$route.query.end || '';
      },
    },
    apollo: {
      facets: {
        query: gql`query SearchWorks($title: String!, $kinds: String!, $collections: String!, $artists: String!, $mediums: String!, $locations: String!, $workDates: String!, $methodologies: String!, $pigments: String!, $colors: String!, $elements: String!, $startDate: Date, $endDate: Date) {
          allWorks(search: $title, kindsFacet: $kinds, collectionsFacet: $collections, artistsFacet: $artists, mediumsFacet: $mediums, locationsFacet: $locations, workDatesFacet: $workDates, methodologiesFacet: $methodologies, pigmentsFacet: $pigments, colorsFacet: $colors, elementsFacet: $elements, startDate: $startDate, endDate: $endDate) {
             facets {
              kinds
              collections
              artists
              mediums
              locations
              workDates
              methodologies
              pigments
              colors
              elements
            }
          }
        }`,
        variables () {
          const defaultFacets = FACET_KEYS.reduce((map, key) => ({...map, [key]: ''}), {});
          const startDate = this.startYear ? `${String(this.startYear).padStart(4, '0')}-01-01` : null;
          const endDate = this.endYear ? `${this.endYear}-12-31` : null;
          const dates = startDate && endDate ? { startDate, endDate } : {};
          return {
            title: this.search,
            ...dates,
            ...defaultFacets,
            ...this.selectedFacets,
          };
        },
        update: data => {
          return data.allWorks.facets;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
