<template>
  <div :style="{ background }"></div>
</template>

<script>
  export default {
    props: {
      color: Object,
    },
    computed: {
      background() {
        if (this.color.hexCodeLeftGradient && this.color.hexCodeRightGradient) {
          const { hexCodeLeftGradient, hexCode, hexCodeRightGradient } = this.color;
          return `linear-gradient(0.25turn, #${hexCodeLeftGradient}, #${hexCode}, #${hexCodeRightGradient})`;
        }
        return `#${this.color.hexCode}`;
      },
    },
  };
</script>
