<template>
  <div>
    <div class="visible-color-name">{{ color.name }}</div>
    <ColorSwatch v-if="color.hexCode" class="w-16 h-16 border border-black rounded-sm" :color="color" />
  </div>
</template>

<script>
  import ColorSwatch from './ColorSwatch.vue';

  export default {
    props: {
      color: Object,
    },
    components: { ColorSwatch },
    computed: {
      background() {
        if (this.color.hexCodeLeftGradient && this.color.hexCodeRightGradient) {
          const { hexCodeLeftGradient, hexCode, hexCodeRightGradient } = this.color;
          return `linear-gradient(0.25turn, #${hexCodeLeftGradient}, #${hexCode}, #${hexCodeRightGradient})`;
        }
        return `#${this.color.hexCode}`;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
