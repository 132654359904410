<template>
    <div class="container top" v-html="block.value.title"></div>
    <div class="container middle" v-html="block.value.text"></div>
    <div class="container bottom"><a v-bind:href="block.value.link"> Read More </a></div>
</template>

<script>
export default {
  props: {
    block: Object
  },
};
</script>

<style scoped>
@import '../../../../css/dynamic.css';
</style>