<template>
  <div class="flex align-middle justify-center py-4 text-gray-400">
    <div class="mx-1" v-for="(facet, index) in facets" :key="index">
      {{ facet.facet }}
      <a href @click.prevent="$emit('clear-facet', facet)">&#x2573;</a>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['facetsByCategory'],
    computed: {
      categories() {
        return Object.keys(this.facetsByCategory);
      },
      categoryFacets() {
        return this.categories.map(category => {
          return { category, facet: this.facetsByCategory[category] };
        });
      },
      facets() {
        return this.categoryFacets.flat();
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
