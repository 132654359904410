<template>
  <div class="frontend-app">
    <div v-if="front">
      <!-- <Hamburger class="absolute border-white" :open="showMenu" @toggle="toggleMenu" /> -->
      <div class="mx-auto text-center relative">
        <!-- div without absolute is good for menu, but not for main -->
        <Hamburger class="absolute right-0" :open="showMenu" openColor="black" closedColor="white" @toggle="toggleMenu" />
      </div>
      <Menu v-if="showMenu" @navigated="toggleMenu"></Menu>
      <div v-else>
        <Splash />
        <router-view />
      </div>
    </div>
    <div v-else-if="healthcheckRoute">
      <pre id="status">{{ healthcheck }}</pre>
    </div>
    <div v-else>
      <div class="container mx-auto text-center relative">
        <img class="w-3/4 inline-block" :src="logo">
        <Hamburger class="inline-block absolute bottom-12 right-0" :open="showMenu" @toggle="toggleMenu" />
      </div>
      <Menu v-if="showMenu" @navigated="toggleMenu"></Menu>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
  import Logo from '../../images/color/logo.svg';
  import Hamburger from './components/navigation/Hamburger.vue';
  import Menu from './components/navigation/Menu.vue';
  import Splash from './components/navigation/Splash.vue';

  export default {
    components: {
      Hamburger,
      Menu,
      Splash
    },
    data() {
      return {
        showMenu: false,
        healthcheck: {
          "status": "ok",
        }
      };
    },
    computed: {
      logo() {
        return Logo;
      },
      front(){
        return this.$route.name == "home-view";
      },
      healthcheckRoute(){
        return this.$route.name == "healthcheck";
      }
    },
    methods: {
      toggleMenu() {
        console.log('toggle');
        this.showMenu = !this.showMenu;
      },
    },
  }
</script>
<style>
.full-width{
  width: 100%;;
}
</style>